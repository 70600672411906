.prop {
	width: 100%;
	height: rem(550);
	cursor: pointer;
	position: relative;
	transition: ease 200ms all;

	&:hover {
		img {
			max-width: 72%;
			max-height: 72%;
		}
	}

	&__link {
		width: 100%;
	}

	&__image {
		display: flex;
		align-items: center;
		justify-content: center;
		background-color: #090607;
		height: 75%;
		width: 100%;
		overflow: hidden;

		img {
			max-width: 70%;
			max-height: 70%;
			transform: rotate(-90deg);
			transition: all ease 200ms;
		}
	}

	&__textcontainer {
		height: 25%;
		width: 100%;
		display: flex;
		align-items: center;
		border-bottom: 1px solid #1e1e1e;
	}

	&__text {
		h4 {
			font-size: 1rem;
			font-weight: 700;
			letter-spacing: rem(3.6px);
		}
		p {
			margin-top: rem(25);
			font-size: rem(14);
			font-weight: 300;
		}
	}
}

@media (max-width: 1395px) {
	.prop {
		&__image {
			height: 65%;
			img {
				transform: rotate(0deg);
			}
		}
	}
}

@media (max-width: 960px) {
	.prop {
		height: rem(400);

		// &::before {
		// 	content: '';
		// 	position: absolute;
		// 	display: block;
		// 	right: -8px;
		// 	height: 700px;
		// 	width: 1px;
		// 	opacity: 0;
		// 	background-color: $mainBlack;
		// }

		&__image {
			height: 65%;
			img {
				transform: rotate(0deg);
			}
		}

		&__textcontainer {
			height: 35%;
		}
	}
}

@media (max-width: 600px) {
	.prop {
		margin-bottom: rem(40);
	}
}
