.requestsuccess {
	position: fixed;
	z-index: 10;
	width: 50%;
	height: rem(400);
	left: 25%;
	background: $mainWhite;
	margin-top: 1.5rem;
	z-index: 45;
	text-align: end;

	img {
		height: 30px;
		width: 30px;
		margin-top: rem(20);
		margin-right: rem(20);
		transition: ease 200ms all;
		cursor: pointer;

		&:hover {
			transform: scale(1.3);
		}
	}

	&__container {
		height: 100%;
		width: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: space-evenly;
		padding-bottom: rem(100);
		text-align: center;
		padding-right: rem(10);
		padding-left: rem(10);
	}
}

@media (max-width: 1200px) {
	.requestsuccess {
		width: 80%;
		left: 10%;
		top: 100px;
	}
}
