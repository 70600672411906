.mobilemenu {
	position: fixed;
	z-index: 30;
	width: 70%;
	height: rem(200px);
	left: 15%;
	background: $mainWhite;
	margin-top: 1.5rem;
	z-index: 45;

	&__links {
		display: flex;
		flex-direction: column;
		width: 100%;
		height: 100%;
		align-items: center;
		justify-content: space-around;

		a {
			height: 100%;
			position: relative;
			width: 100%;

			display: flex;
			align-items: center;
			justify-content: center;
			transition: all 100ms ease;

			&:not(:last-child) {
				&::before {
					content: '';
					display: block;
					position: absolute;
					height: 1px;
					width: 80%;
					left: 0;
					right: 0;
					bottom: 0px;
					background-color: $mainBlack;
					opacity: 1;
					transform: translateX(12.5%);
				}
			}

			&:hover {
				font-weight: 700;
			}
		}
	}
}

@media (min-width: 960px) {
	.mobilemenu {
		display: none;
	}
}
