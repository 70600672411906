.navbar {
	width: 100%;
	height: rem(64px);
	display: flex;
	border-bottom: 1px solid $mainBlack;
	justify-content: space-between;
	align-items: center;
	z-index: 50;
	padding: 0rem rem(130px);
	background: $mainWhite;

	&.open {
		.navbar__toggle {
			> span:first-child {
				transform: rotate(45deg);
			}
			> span:nth-child(2) {
				opacity: 0;
			}
			> span:last-child {
				transform: rotate(-45deg);
			}
		}
	}

	&__logo {
		height: rem(49px);
		transition: transform 200ms;

		a {
			display: flex;
			align-items: center;

			p {
				font-weight: 500;
				font-size: 1rem;
				color: #6e6e6e;
				margin-left: 1rem;
			}
		}

		&:hover {
			transform: scale(1.1);
		}
	}

	&__links {
		display: flex;
		align-items: center;

		li {
			&:not(:last-child) {
				margin-right: rem(190px);
			}

			a {
				position: relative;
				font-weight: 500;
				font-size: 1rem;
				color: #6e6e6e;
				transition: color 200ms ease;

				&::before {
					content: '';
					display: block;
					position: absolute;
					height: 2px;
					left: 0;
					right: 0;
					bottom: -8px;
					background-color: $mainBlack;
					opacity: 0;
					transition: opacity 200ms ease;
				}

				&.active {
					color: #000;
					&::before {
						opacity: 1;
					}
				}

				&.cartActive {
					img {
						border-bottom: 2px solid black;
					}
				}

				&:hover {
					color: #000;
					&::before {
						opacity: 1;
					}
				}
			}

			.cartLink {
				&::before {
					content: none;
				}
			}
		}
	}

	// Added in the styling for the hamburger

	&__toggle {
		//Display of none for when on fullscreen
		display: none;
		cursor: pointer;
		> span {
			display: block;
			width: 40px;
			height: 2px;
			background-color: $mainBlack;
			transition: all 300ms ease-in-out;
			transform-origin: 5.8px 1px;

			&:not(:last-child) {
				margin-bottom: 8px;
			}
		}
	}
}
.cart {
	position: relative;
	height: 35px;
	width: 35px;
	text-align: center;
	transition: all 200ms ease;

	&:hover {
		transform: scale(1.1);
	}
	img {
		position: absolute;
		height: 38px;
		width: 38px;
		left: -18px;
	}
}

.cart-mobile {
	display: none;
	position: relative;
	height: 35px;
	width: 35px;
	text-align: center;
	transition: all 200ms ease;

	&:hover {
		transform: scale(1.1);
	}
	img {
		position: absolute;
		height: 38px;
		width: 38px;
		left: 0px;
	}
}

@media (max-width: 960px) {
	.cart-mobile {
		display: block;
	}
	.navbar {
		padding: 0rem rem(30);

		&__logo {
			a {
				p {
					display: none;
				}
			}
		}
	}
	.navbar__links {
		display: none;
	}
	.navbar__toggle {
		display: block;
	}
}

.sticky {
	top: 0;
	position: sticky;
}
