* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}

html {
	&::-webkit-scrollbar {
		width: 0.6rem;
	}
	&::-webkit-scrollbar-thumb {
		background-color: darkgrey;
		border-radius: 1rem;
	}
	&::-webkit-scrollbar-track {
		background: white;
	}
}

body {
	font-family: 'Montserrat', sans-serif;
	background: $mainWhite;
}

img {
	width: 100%;
	object-fit: contain;
}

a {
	text-decoration: none;
	color: $mainBlack;

	:visited {
		text-decoration: none;
		color: $mainBlack;
	}

	:active {
		color: $mainBlack;
	}
}

li {
	list-style: none;
}

// Visibility

.hide-for-mobile {
	@include breakpoint-down(medium) {
		display: none;
	}
}

.hide-for-desktop {
	@include breakpoint-up(large) {
		display: none;
	}
}
