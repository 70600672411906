.requestscreen {
	min-height: 95vh;

	width: 90%;
	max-width: 2000px;
	margin-left: auto;
	margin-right: auto;
	margin-bottom: rem(100);

	&__title {
		margin-top: rem(50);
		margin-bottom: rem(30);

		h1 {
			margin-bottom: rem(30);
		}

		&text {
			display: flex;
			justify-content: space-between;
		}
		&num {
			text-align: end;
			h3 {
				font-weight: 400;
			}
		}
	}

	&__main {
		display: flex;

		&-props {
			flex: 3;
		}

		&-info {
			flex: 2;
		}
	}

	&__button {
		margin-top: rem(60);
		display: flex;
		justify-content: center;

		button {
			margin-left: auto;
			margin-right: auto;

			color: $mainBlack;
			width: auto;

			text-transform: uppercase;
			text-decoration: none;
			font-weight: 500;
			background: #ffffff;
			padding: 20px;
			border: 2px solid #494949;
			border-radius: 50px;
			display: inline-block;
			transition: all 0.4s ease 0s;
			cursor: pointer;

			&:hover {
				color: #ffffff;

				background: #78838d;
				border-color: #78838d;
				transition: all 0.4s ease 0s;
			}
		}
	}
}

@media (max-width: 960px) {
	.requestscreen {
		&__main {
			display: block;
		}
	}
}
