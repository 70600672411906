.propsscreen {
	width: 100%;
	min-height: 100vh;
	padding: 0rem 20px;
	position: relative;
	overflow: hidden;

	&__title {
		text-align: center;
		width: auto;
		margin-top: rem(70px);
		margin-left: auto;
		margin-right: auto;
		font-size: rem(45);
		letter-spacing: 1px;
		font-weight: 700;
	}

	li {
		cursor: pointer;
		position: relative;

		&::before {
			content: '';
			display: block;
			position: absolute;
			height: 3px;
			left: 0;
			right: 0;
			bottom: -8px;
			background-color: $mainBlack;
			opacity: 0;
			transition: opacity 200ms ease;
		}

		&:hover {
			color: #000;
			&::before {
				opacity: 1;
			}
		}
	}

	&__linksone {
		margin-top: rem(40);
		display: flex;
		justify-content: center;
		font-size: rem(20px);
		color: #636363;
		ul {
			display: flex;
			width: rem(850px);
			align-items: center;
			justify-content: space-between;
		}
	}

	&__linkstwo {
		margin-top: rem(60);
		display: flex;
		justify-content: center;
		font-size: rem(20px);
		color: #636363;
		ul {
			display: flex;
			width: rem(850px);
			align-items: center;
			justify-content: space-between;
		}
	}

	&__mobile-links {
		width: 100%;
		justify-content: center;
		padding-top: rem(50);
		position: relative;
		display: none;
		height: 90px;

		select {
			appearance: none;
			background-color: transparent;
			border: none;
			padding: 0 1em 0 0;
			margin: 0;
			width: 100%;
			font-family: inherit;
			font-size: inherit;
			cursor: inherit;
			line-height: inherit;
			outline: none;
		}

		select,
		.select:after {
			grid-area: select;
		}

		select:focus + .focus {
			position: absolute;
			top: -1px;
			left: -1px;
			right: -1px;
			bottom: -1px;
			border: 2px solid black;
			border-radius: inherit;
		}

		.select {
			width: 100%;
			min-width: 15ch;
			max-width: 30ch;
			border: 1px solid black;
			// border-radius: 0.25em;
			padding: 0.25em 0.5em;
			font-size: 1.25rem;
			cursor: pointer;
			line-height: 1.1;
			background-color: white;
			background-image: linear-gradient(to top, #f9f9f9, #fff 33%);
			display: grid;
			grid-template-areas: 'select';
			align-items: center;
			position: relative;

			&::after {
				content: '';
				width: 0.8em;
				height: 0.5em;
				background-color: black;
				clip-path: polygon(100% 0%, 0 0%, 50% 100%);
				justify-self: end;
			}
		}
	}

	&__loadmorecontainer {
		display: flex;
		justify-content: center;
		padding-top: rem(80);
	}

	&__loadmore {
		color: $mainBlack;
		text-transform: uppercase;
		text-decoration: none;
		font-weight: 500;
		background: #ffffff;
		padding: 20px;
		border: 2px solid #494949;
		display: inline-block;
		transition: all 0.4s ease 0s;
		cursor: pointer;
		&:hover {
			color: #ffffff;
			background: #78838d;
			border-color: #78838d;
			transition: all 0.4s ease 0s;
		}
	}

	&__props {
		margin-top: rem(120px);
		display: grid;
		grid-template-columns: repeat(auto-fit, minmax(420px, 1fr));
		column-gap: rem(40px);
		row-gap: rem(60px);
		justify-items: center;
		overflow: hidden;
	}

	&__white {
		height: rem(80);
		width: 100%;
		background-color: $mainWhite;
		z-index: 100;
	}
}

.selected {
	color: black;
	font-weight: 600;
	// border-bottom: black solid 2px;

	&::after {
		content: '';
		display: block;
		position: absolute;
		height: 2px;
		left: 0;
		right: 0;
		bottom: -8px;
		background-color: $mainBlack;
		opacity: 1;
		transition: opacity 200ms ease;
	}
}

@media (min-width: 2000px) {
	.propscreen {
		&__props {
			grid-template-columns: repeat(auto-fit, minmax(800px, 1fr));
			column-gap: rem(15px);
		}
	}
}

@media (max-width: 960px) {
	.propsscreen {
		font-size: rem(12);
		padding: 0rem rem(30);
		&__title {
			font-size: rem(25);
		}

		&__props {
			grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
			column-gap: rem(15px);
			// display: block;
		}

		&__links {
			display: none;
		}

		&__mobile-links {
			display: flex;
		}
	}
}

@media (max-width: 600px) {
	.propsscreen {
		&__props {
			display: block;
		}
	}
}
