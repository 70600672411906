.homescreen {
	max-width: rem(1400);
	margin-left: auto;
	margin-right: auto;
	min-height: 100vh;

	h1 {
		font-size: rem(50);
		font-weight: 700;
		letter-spacing: rem(4.2);
		color: #1d1d1f;
	}
	h4 {
		font-size: rem(28);
		font-weight: 500;
		letter-spacing: rem(2);
		color: #818181;
	}

	p {
		font-size: rem(16);
		line-height: rem(31);
		letter-spacing: rem(0.7);
		color: #808080;
	}
	&__title {
		margin-top: rem(50);
		margin-bottom: rem(100);
		&text {
			margin-left: rem(40);
		}
		display: flex;
		align-items: center;
		justify-content: center;
		img {
			height: rem(125);
			width: rem(125);
		}

		h4 {
			margin-left: rem(2);
		}
	}

	&__collection {
		display: flex;
		flex-direction: row-reverse;
		align-items: center;
		justify-content: space-between;
		margin-bottom: rem(150);
		&image {
			overflow: hidden;

			img {
				width: 100%;
				object-fit: cover;
			}
		}
		&-body {
			width: 90%;
			max-width: rem(600);
			margin-right: rem(40);

			&text {
				margin-bottom: rem(30);

				h3 {
					font-size: rem(44);
					color: $mainBlack;
					letter-spacing: rem(2);
					margin-bottom: rem(50);
				}
				h5 {
					font-size: rem(22);
					letter-spacing: rem(1);
					color: #575757;
					font-weight: 600;
					margin-bottom: rem(25);
				}
				p {
					&:not(:last-child) {
						margin-bottom: rem(40);
					}
				}
			}
			.button-container {
				display: flex;
				justify-content: space-evenly;
				margin-top: rem(30);

				img {
					height: 12%;
					width: 12%;
					transition: transform 200ms;
					cursor: pointer;

					&:hover {
						transform: scale(1.1);
					}
				}
			}
			&-button {
				color: $mainBlack;
				width: auto;

				text-transform: uppercase;
				text-decoration: none;
				font-weight: 500;
				background: #ffffff;
				padding: 20px;
				border: 2px solid #494949;
				display: inline-block;
				transition: all 0.4s ease 0s;
				cursor: pointer;

				&:hover {
					p {
						color: #ffffff;
					}

					background: #78838d;
					border-color: #78838d;
					transition: all 0.4s ease 0s;
				}
			}
		}
	}

	&__seenin {
		margin-bottom: rem(170);
		display: grid;
		grid-template-columns: 1fr 1fr 1fr;
		grid-template-rows: 1fr 1fr;
		gap: rem(35);
		grid-template-areas:
			'image1 image2 text'
			'image3 image4 image5';

		&text {
			display: flex;
			flex-direction: column;
			justify-content: center;
			border-top: solid $mainBlack 1px;
			border-bottom: solid $mainBlack 1px;
			grid-area: text;
			span {
				color: #171717;
			}
			h1 {
				margin-bottom: rem(50);
			}
			h4 {
				margin-bottom: rem(20);
			}
			p {
				&:not(:last-child) {
					margin-bottom: rem(15);
				}
			}
		}
	}
}

@media (max-width: 1470px) {
	.homescreen {
		margin: 0 rem(35);

		h1 {
			font-size: rem(40);
		}
		h4 {
			font-size: rem(20);
		}

		p {
			font-size: rem(14);
		}

		&__title {
			img {
				height: rem(100);
				width: rem(100);
			}
		}

		&__collection {
			&image {
				flex: 1.3;

				img {
					padding-left: 10%;
				}
			}
			&-body {
				width: 90%;
				max-width: rem(600);

				flex: 1;

				img {
					width: 100%;
				}

				&text {
					margin-bottom: rem(30);

					h3 {
						font-size: rem(32);
					}
					h5 {
						font-size: rem(18);

						margin-bottom: rem(20);
					}
					p {
						&:not(:last-child) {
							margin-bottom: rem(15);
						}
					}
				}
				&-button {
					padding: rem(15);
				}
			}
		}

		&__seenin {
			&text {
				h1 {
					font-size: rem(30);
				}
			}
		}
	}
}

@media (max-width: 1150px) {
	.homescreen {
		&__collection {
			&image {
				flex: 1;
			}
			img {
				padding-left: rem(0);
			}
			&-body {
				img {
					height: rem(120);
					object-fit: cover;
				}
				&text {
					h3 {
						font-size: rem(30);
						margin-bottom: rem(40);
					}
				}
			}
		}
	}
}

@media (max-width: 960px) {
	.homescreen {
		margin: 0 rem(30);
		&__title {
			margin: rem(30) 0;
			&text {
				margin-left: rem(20);
			}

			img {
				height: rem(60);
				width: rem(60);
			}

			h1 {
				font-size: rem(30);
			}

			h4 {
				font-size: rem(18);
			}
		}

		&__collection {
			display: block;
			margin-bottom: rem(100);

			&image {
				img {
					padding: 0;

					max-height: rem(650);
					object-fit: contain;
				}
			}

			&-body {
				margin-top: rem(40);
				max-width: 100%;
				width: 100%;

				.button-container {
					img {
						display: none;
					}
				}

				&collage {
					width: 100%;
					margin-top: rem(35);
				}

				img {
					height: rem(200);
					margin-bottom: rem(25);
				}

				&text {
					width: 100%;

					h3 {
						text-align: center;
						margin-bottom: rem(50);
					}

					h5 {
						// text-align: center;
					}
				}
			}
		}

		&__seenin {
			margin-bottom: rem(60);
			display: grid;
			grid-template-columns: 1fr 1fr;
			grid-template-rows: 0.5fr 1fr 1fr 1fr;
			gap: rem(15);
			grid-template-areas:
				'text text'
				'image1 image2'
				'image3 image4'
				'image5 .';

			&text {
				margin-bottom: rem(40);
				border-bottom: none;

				h1 {
					margin-top: rem(30);
				}
			}
		}
	}
}

@media (max-width: 600px) {
	.homescreen {
		&__title {
			margin: rem(30) 0;
			&text {
				margin-left: rem(15);
			}

			img {
				height: rem(50);
				width: rem(50);
			}

			h1 {
				font-size: rem(20);

				letter-spacing: rem(1.5);
			}

			h4 {
				font-size: rem(15);
				letter-spacing: rem(1);
			}
		}

		&__collection {
			display: block;
			margin-bottom: rem(60);

			&image {
				img {
					padding: 0;
					// display: none;

					max-height: rem(250);
					object-fit: contain;
				}
			}

			&-body {
				margin-top: rem(40);
				max-width: 100%;
				width: 100%;

				&collage {
					width: 100%;
					margin-top: rem(35);
				}

				img {
					height: rem(200);
					margin-bottom: rem(25);
				}

				&text {
					width: 100%;

					h3 {
						text-align: center;
						margin-bottom: rem(30);
						font-size: rem(25);
						letter-spacing: rem(1);
					}

					h5 {
						font-size: rem(16);
					}
				}
			}
		}
		&__seenin {
			margin-bottom: rem(60);
			display: block;

			img {
				margin-bottom: 3%;
			}

			&text {
				h1 {
					font-size: rem(30);
					margin-top: rem(30);
					margin-bottom: rem(20);
				}
				h4 {
					margin-bottom: rem(10);
				}
				p {
					&:not(:last-child) {
						margin-bottom: rem(10);
					}
				}
			}
		}
	}
}

@media (max-width: 390px) {
	.homescreen {
		&__title {
			margin: rem(30) 0;
			&text {
				margin-left: rem(10);
			}

			img {
				height: rem(35);
				width: rem(35);
			}

			h1 {
				font-size: rem(14);

				letter-spacing: rem(1.5);
			}

			h4 {
				font-size: rem(10);
				letter-spacing: rem(1);
			}
		}
	}
}
