.requestinfo {
	margin-left: rem(30);
	box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
	min-height: rem(500);
	padding: rem(30);
	display: flex;
	flex-direction: column;
	justify-content: space-around;

	&__title {
		text-align: center;
	}
	$primary-color: $mainWhite;
	$error-color: #e63946;
	$text-color: #293241;
	$color-white: black;
	$shadow-color: #dedee6;
	$border-radius: 4px;

	h1 {
		font-size: 2rem;
		color: $text-color;
		margin-bottom: 2rem;
	}

	label,
	input {
		color: $text-color;
	}

	label {
		font-size: 1.4rem;
		margin-bottom: 0.5rem;
	}

	input {
		box-sizing: border-box;
		-webkit-box-sizing: border-box;
		-moz-box-sizing: border-box;
		outline: none;
		display: block;
		width: 100%;
		padding: 7px;
		border: none;
		border-bottom: 1px solid #ddd;
		background: transparent;
		margin-bottom: rem(30);
		font-size: 1rem;
		height: 45px;
		letter-spacing: 1px;
	}

	.comment {
		textarea {
			height: 150px;
			width: 100%;
			font-family: 'Montserrat', sans-serif;
			font-size: 1rem;
			padding: rem(8);
			resize: vertical;
		}
	}

	.form-row {
		display: flex;
		flex-direction: column;
		margin-bottom: 1.5rem;
	}

	button {
		text-transform: uppercase;
		color: $color-white;
		width: 100%;
		font-size: 1.2rem;
		background: $primary-color;
		border-radius: $border-radius;
		border: 2px solid #494949;
		cursor: pointer;
		padding: rem(20);
		margin-top: 0.8rem;
		transition: all 0.4s ease 0s;

		&:hover {
			color: #ffffff;

			background: #78838d;
			border-color: #78838d;
			transition: all 0.4s ease 0s;
		}
	}

	.input-error {
		border-color: $error-color;
	}

	.error {
		color: $error-color;
		font-size: 1rem;
		margin-top: 0.3rem;
	}

	.disabled-btn {
		cursor: not-allowed;
		background: rgba($primary-color, 0.8);

		&:hover {
			background: $primary-color;
			color: $color-white;
			border: 2px solid #494949;
		}
	}
}

@media (max-width: 960px) {
	.requestinfo {
		margin: 0;
	}
}
