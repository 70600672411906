.contactscreen {
	min-height: 95vh;
	display: flex;
	align-items: center;
	justify-content: center;
	font-weight: 500;
	color: #262626;
	font-size: rem(20);
	letter-spacing: rem(1.4);

	&__container {
		margin-top: rem(50);
		width: rem(1400);
		padding-bottom: rem(80);
	}

	&__title {
		display: flex;
		align-items: center;
		padding-bottom: rem(45);

		img {
			height: rem(73);
			width: rem(73);
		}

		h2 {
			font-size: rem(50);
			font-weight: 700;
			letter-spacing: rem(1.75);
			color: #090607;
			padding-left: rem(30);
		}
	}

	&__main {
		display: flex;
		align-items: center;
		padding-top: rem(50);
		height: 100%;

		img {
			width: rem(80);
			height: rem(40);
			padding-right: rem(40);
		}
	}

	&__contact {
		margin-right: rem(50);
	}

	&__phone {
		padding-bottom: rem(40);

		a {
			display: flex;
			align-items: center;
		}
		img {
			transition: 200ms all ease;
		}

		&:hover {
			img {
				transform: scale(1.3);
			}
		}
	}

	&__email {
		padding-bottom: rem(40);

		a {
			display: flex;
			align-items: center;
		}
		img {
			transition: 200ms all ease;
		}

		&:hover {
			img {
				transform: scale(1.3);
			}
		}
	}

	&__website {
		padding-bottom: rem(40);

		a {
			display: flex;
			align-items: center;
		}
		img {
			transition: 200ms all ease;
		}

		&:hover {
			img {
				transform: scale(1.3);
			}
		}
	}

	&__location {
		a {
			display: flex;
		}

		p {
			&:not(:last-child) {
				padding-bottom: rem(5);
			}
		}
		img {
			transition: 200ms all ease;
		}

		&:hover {
			img {
				transform: scale(1.3);
			}
		}
	}

	&__map {
		position: relative;
		height: 600px;
		width: 100%;

		//shadow
		-webkit-box-shadow: 2px 10px 15px 2px rgba(0, 0, 0, 0.4); /* Safari 3-4, iOS 4.0.2 - 4.2, Android 2.3+ */
		-moz-box-shadow: 2px 10px 15px 2px rgba(0, 0, 0, 0.4); /* Firefox 3.5 - 3.6 */
		box-shadow: 2px 8px 15px 2px rgba(0, 0, 0, 0.4); /* Opera 10.5, IE 9, Firefox 4+, Chrome 6+, iOS 5 */
	}
}

@media (max-width: 1500px) {
	.contactscreen {
		font-size: rem(16);

		&__container {
			width: 95%;
		}
		&__contact {
			padding-bottom: rem(50);

			margin-right: rem(0);
		}

		&__main {
			display: block;
			img {
				width: rem(45);
				height: rem(30);
				padding-right: rem(15);
			}
		}
	}
}

@media (max-width: 960px) {
	.contactscreen {
		&__main {
			padding-top: rem(40);
			display: block;
			font-size: rem(12);

			img {
				width: rem(25);
				height: rem(20);
				padding-right: rem(5);
			}
		}

		&__title {
			padding-bottom: rem(40);
			img {
				display: none;
			}
			h2 {
				padding-bottom: 0;
				padding-left: 0;
				font-size: rem(40);
			}
		}

		&__map {
			height: rem(300);
		}

		&__contact {
			padding-bottom: rem(50);

			margin-right: rem(0);
		}

		&__map {
			width: 100%;
		}

		&__phone {
			padding-bottom: rem(30);
		}

		&__email {
			padding-bottom: rem(30);
		}
	}
}

.map {
	position: absolute;
	top: 0;
	bottom: 0;
	width: 100%;
	height: 100%;
}

.line {
	height: 1px;
	background-color: $mainBlack;
}
